import {createRouter, createWebHistory} from 'vue-router';

import {useAuthStore, useAlertStore} from '@/stores';
import {Home} from '@/views';
import accountRoutes from './account.routes';
import appointmentsRoutes from './appointments.routes';
import formsRoutes from './forms.routes';
import patientRoutes from './patient.routes';
import usersRoutes from './users.routes';

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: 'active',
    routes: [
        {path: '/', component: Home},
        {...accountRoutes},
        {...appointmentsRoutes},
        {...formsRoutes},
        {...patientRoutes},
        {...usersRoutes},
        // catch all redirect to home page
        {path: '/:pathMatch(.*)*', redirect: '/'}
    ]
});

router.beforeEach(async (to) => {
    // clear alert on route change
    const alertStore = useAlertStore();
    alertStore.clear();

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/account/login', '/account/register', '/account/forgot-pwd'];
    const authRequired = !publicPages.includes(to.path);
    const authStore = useAuthStore();

    if (authRequired && !authStore.user) {
        authStore.returnUrl = to.fullPath;
        return '/account/login';
    }
});
