<template>
    <div class="row">
        <div class="col">
            <div class="page-title q-pb-md q-pt-md">
                HIPAA Notice of Privacy Practices
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ isSubmitting }" class="q-mb-md bamboo-right">
                <q-card bordered class="form-card">
                    <q-card-section>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="firstName"
                                        label="First Name"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col login-btn-container deco-link">
                                <q-btn rounded color="accent" size="md" padding="9px 24px" type="submit" :disabled="isSubmitting">
                                    <span v-show="isSubmitting" class="spinner-border spinner-border-sm q-mr-sm"></span>
                                    Submit
                                </q-btn>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </Form>
        </div>
    </div>
</template>

<script setup>
import {Form} from "vee-validate";
import {QInputWithValidation} from "@/components/form";
import * as Yup from "yup";

const schema = Yup.object().shape({
    sex: Yup.string().required('This is required'),
    address: Yup.string().required('Address is required')
});

async function onSubmit(values) {
    console.log('saving info...', values)
}

</script>
