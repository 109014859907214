import {Layout, List, AddEdit} from '@/views/users';
import {UserSettings} from '@/views/patient'

export default {
    path: '/user',
    component: Layout,
    children: [
        {path: '', component: List},
        {path: 'settings', component: UserSettings},
        {path: 'add', component: AddEdit},
        {path: 'edit/:id', component: AddEdit}
    ]
};
