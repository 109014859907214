<template>
    <div class="row">
        <div class="col">
            <div class="page-title q-pb-md q-pt-md">
                Sign in to Simple Harmony
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ isSubmitting }" class="q-mb-md bamboo-right">
                <q-card bordered class="form-card">
                    <q-card-section>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="email"
                                        label="Email"
                                        placeholder="user@example.com"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QPasswordWithValidation
                                        name="password"
                                        label="Password"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col login-btn-container deco-link">
                                <q-btn rounded color="accent" size="md" padding="9px 24px" type="submit" :disabled="isSubmitting">
                                    <span v-show="isSubmitting" class="spinner-border spinner-border-sm q-mr-sm"></span>
                                    Sign In
                                </q-btn>
                                <router-link to="reset-pwd">Forgot Password?</router-link>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
                <q-card class="form-card">
                    <q-card-section>
                        <div class="row">
                            <div class="col deco-link">
                                New to Simple Harmony? <router-link to="register" class="btn btn-link">Sign up here</router-link>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </Form>
        </div>
    </div>
</template>

<script setup>
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import { useAuthStore } from '@/stores';
import {QInputWithValidation, QPasswordWithValidation} from '@/components/form'

const schema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Please enter a valid email address'),
    password: Yup.string().required('Password is required')
});

async function onSubmit(values) {
    const authStore = useAuthStore();
    console.log('values: ', values)
    const { email, password } = values;
    console.log('email:', email, ', pwd:', password)
    await authStore.login(email, password);
}

</script>
