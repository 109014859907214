import script from "./HeaderNav.vue?vue&type=script&setup=true&lang=js"
export * from "./HeaderNav.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QImg,QToolbarTitle,QAvatar,QMenu,QList,QItem,QItemSection,QSeparator,QDrawer,QIcon});qInstall(script, 'directives', {ClosePopup,Ripple});
