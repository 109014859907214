<template>
    <div class="row">
        <div class="col">
            <div class="page-title q-pb-md q-pt-md">
                Patient Information
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Form @submit="onSubmit" :initial-values="initialValues" :validation-schema="schema"
                  v-slot="{ isSubmitting }" class="q-mb-md bamboo-right">
                <q-card bordered class="form-card-lg">
                    <q-card-section>
                        <div class="row">
                            <div class="col col-sm-12 q-mb-sm">
                                <QInputWithValidation
                                        name="fullName"
                                        label="Full Name"
                                        placeholder=""
                                />
                            </div>
                            <div class="col col-sm-12 q-mb-sm">
                                <QInputWithValidation
                                        name="nickname"
                                        label="Preferred Name"
                                        placeholder="Nickname"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                Sex
                                <div class="q-gutter-sm">
                                    <q-radio v-model="sex" val="m" label="Male" />
                                    <q-radio v-model="sex" val="f" label="Female" />
                                </div>
                            </div>
                            <div class="col q-mb-sm">
                                <q-input filled v-model="dob" mask="date" :rules="['date']" class="form-date">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer">
                                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                                <q-date v-model="dob">
                                                    <div class="row items-center justify-end">
                                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                                    </div>
                                                </q-date>
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col q-mb-sm">
                                <q-select v-model="maritalStatus" :options="maritalStatusOptions" label="Marital Status"
                                          class="form-select" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="address"
                                        label="Address"
                                        placeholder="Street Address"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="city"
                                        label="City"
                                        placeholder=""
                                />
                            </div>
                            <div class="col q-mb-sm">
                                <q-select filled v-model="state" :options="states" label="State"
                                    style="width: 250px" class="form-select"/>
                            </div>
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="zip"
                                        label="Zip"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="dayPhone"
                                        label="Daytime Phone #"
                                        placeholder=""
                                />
                            </div>
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="altPhone"
                                        label="Alternate Phone #"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="emergencyContact"
                                        label="Emergency Contact"
                                        placeholder=""
                                />
                            </div>
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="contactPhone"
                                        label="Emergency Contact Phone #"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="doctor"
                                        label="Primary Care Doctor"
                                        placeholder=""
                                />
                            </div>
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="doctorSpecialty"
                                        label="Specialty"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="otherDoctor"
                                        label="Other Doctors You See"
                                        placeholder=""
                                />
                            </div>
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="otherSpecialty"
                                        label="Specialty"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <!--TODO can move this cancellation policy checkbox to booking appt section?-->
                                Cancellation Policy
                                <QCheckboxWithValidation
                                        name="cancellationPolicy"
                                        label="I acknowledge that I will give at least 24 hours notice of cancellation to avoid a charge for the session. This si a courtesy to other patients who may need that appointment time. I will call if I anticipate being more than 15 minutes late for my appointment."
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col login-btn-container deco-link">
                                <q-btn rounded color="accent" size="md" padding="9px 24px" type="submit" :disabled="isSubmitting">
                                    <span v-show="isSubmitting" class="spinner-border spinner-border-sm q-mr-sm"></span>
                                    Submit
                                </q-btn>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </Form>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {Form} from "vee-validate";
import {QInputWithValidation, QCheckboxWithValidation} from "@/components/form";
// import {QInputWithValidation} from "@/components/form";
import * as Yup from "yup";

const state = ref(null)
const sex = ref('')
const dob = ref('2019/02/01')
const maritalStatus = ref(null)

const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
    'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
    'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']

const maritalStatusOptions = [
    'Single', 'Married', 'Separated', 'Divorced'
]

const initialValues = {
    cancellationPolicy: false,
}

const schema = Yup.object().shape({
    fullName: Yup.string().required('Yur full name is required'),
    sex: Yup.string().required('This is required'),
    address: Yup.string().required('Address is required')
});

async function onSubmit(values) {
    console.log('saving info...', values)
}

</script>
