import axios from 'axios';
import { defineStore } from 'pinia';

const HOST = 'https://book.simpleharmony.co'

export const useServiceStore = defineStore({
    id: 'service',
    state: () => ({
        services: null,
        servicesByProvider: []
    }),
    actions: {
        async fetchAllServices() {
            if (!this.services) {
                try {
                    const res = await axios.get(HOST + `/api/services`);
                    let respObj = res.data
                    if (respObj.statusCode === 200 && respObj.success) {
                        // console.log('::STORE services: ', respObj.data)
                        this.services = respObj.data

                        return true
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                return true
            }

            return false
        },
        async fetchServicesByProvider(providerId) {
            console.log('getting services....')
            if (this.services !== null) {
                console.log('FETCHING services...')
                try {
                    const res = await axios.get(HOST + `/api/services/${providerId}`);
                    let respObj = res.data
                    if (respObj.statusCode === 200 && respObj.success) {
                        console.log('::STORE services: ', respObj.data)
                        this.servicesByProvider[providerId] = respObj.data

                        return true
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                return true
            }

            return false
        },
        addService(service) {
            console.log('service: ', service)
        },
        disableService(serviceId) {
            //TODO check first if assigned to any providers
            console.log('serviceId: ', serviceId)
        }
    }
});
