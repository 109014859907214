import { defineStore } from 'pinia';
import {fetchWrapper} from "@/helpers";
import {useAlertStore} from "@/stores/alert.store";

const baseUrl = 'https://book.simpleharmony.co/api'

export const useAppointmentsStore = defineStore({
    id: 'appointments',
    state: () => ({
        appointments: null
    }),
    actions: {
        scheduleAppointment(appointment) {
            console.log('appointment: ', appointment)
        },
        async fetchAllAppointments(userId) {
            console.log('appointments by userId: ', userId)
            if (!this.appointments) {
                try {
                    console.log('fetching appointments...')
                    const respObj = await fetchWrapper.get(`${baseUrl}/appointments/${userId}`)

                    // console.log('resp:', resp)
                    if (respObj.statusCode === 200 && respObj.success) {
                        console.log('::STORE respObj.data: ', respObj.data)
                        this.appointments = respObj.data;

                        return true
                    } else {
                        console.log('NOT A SUCCESSFUL request ', respObj)
                    }
                } catch (error) {
                    const alertStore = useAlertStore();
                    alertStore.error(error);
                }
            } else {
                return true
            }

            return false
        },
        cancelAppointment(appointmentId) {
            console.log('appointmentId: ', appointmentId)
        },
        updateAppointment(appointment) {
            console.log('appointment: ', appointment)
        }
    }
});
