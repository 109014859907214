<template>
    <div class="row">
        <div class="col">
            <div class="page-title q-pb-md q-pt-md">
                Your Upcoming Appointments
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col q-ma-md">
<!--            <button @click="showModal = true">Add Appointment</button>-->
<!--            <appointment-modal v-if="showModal" @close="showModal = false" @submit="addAppointment"></appointment-modal>-->
            <q-markup-table>
                <thead>
                <tr>
                    <th>Service</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="appointment in state.appointments" :key="appointment.id">
                    <td>{{ serviceStore.services[appointment.serviceId] }}</td>
                    <td>{{ convertToLongDate(appointment.date) }}</td>
                    <td>{{ convertFrom24HrClock(appointment.startTime) }}</td>
                    <td class="action-cell">
<!--                        <q-btn rounded @click="editAppointment(appointment.id)" icon="edit" class="q-mr-md"/>-->
<!--                        <q-btn rounded @click="removeAppointment(appointment.id)" icon="delete"/>-->
                        <q-icon @click="editAppointment()" name="edit" color="green" size="sm" class="q-mr-md" />
<!--                        <q-icon @click="removeAppointment()" name="delete" color="red" size="sm" />-->
                        <q-icon @click="confirm = true" name="delete" color="red" size="sm" />
                    </td>
                </tr>
                </tbody>
            </q-markup-table>
            <q-dialog v-model="confirm" persistent>
                <q-card>
                    <q-card-section class="row items-center">
                        <q-avatar icon="question_mark" color="primary" text-color="white"/>
                        <span class="q-ml-sm">Are you sure you want to cancel the appointment for the ...?</span>
                    </q-card-section>

                    <q-card-actions align="right">
                        <q-btn flat label="No" color="primary" v-close-popup/>
                        <q-btn flat label="Yes, Please Cancel" color="primary" v-close-popup/>
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </div>
    </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {useAuthStore, useServiceStore, useAppointmentsStore} from '@/stores'
// import AppointmentModal from '@/components/AppointmentModal.vue'
import {convertFrom24HrClock, convertToLongDate} from '@/utils/date'

const confirm = ref(false)
const authStore = useAuthStore()
const serviceStore = useServiceStore()
const appointmentStore = useAppointmentsStore()
// const appointments = ref([])
const state = reactive({
    // services: [],
    appointments: [],
});

async function getAppointments() {
    await getAvailableServices()
    await getAllAppointments()
}

async function getAvailableServices() {
    // console.log('getting services...')
    state.services = []
    if (await serviceStore.fetchServicesByProvider(1)) {
        let services = serviceStore.servicesByProvider[1]
        // console.log('>> services: ', services)
        for (let i = 0; i < services.length; i++) {
            // console.log('service name: ', services[i].name)
            state.services[services[i].id] = services[i].name
        }
        // console.log('>> state.services: ', state.services)
    } else {
        console.log('fetchServicesByProvider errored')
    }
}

async function getAllAppointments() {
    if (await appointmentStore.fetchAllAppointments(authStore.user.id)) {
        state.appointments = appointmentStore.appointments
        await getAvailableServices()
    } else {
        console.log('fetchAllAppointments ERRORed')
    }
    // state.appointments = []
    // for (let i = 0; i < appointmentStore.appointments; i++) {
    //     let currAppt = {
    //         'serviceId': appointmentStore.appointments.serviceId,
    //         'providerId': appointmentStore.appointments.providerId,
    //         'date': convertToLongDate(appointmentStore.appointments.date),
    //         'startTime': convertFrom24HrClock(appointmentStore.appointments.startTime)
    //     }
    //     state.appointments.push(currAppt)
    // }
}

// function editAppointment(appointmentId) {
function editAppointment() {
    // console.log('appointmentId: ', appointmentId)
    console.log('editing appointmentId... ')
    // this.$router.push({ name: 'EditAppointment', params: { id: appointmentId } })
}

// // function removeAppointment(appointment) {
// function removeAppointment() {
//     // console.log('appointment: ', appointment)
//     console.log('removing appointment ')
//     // if (window.confirm(`Are you sure you want to remove the appointment for ${appointment.client}?`)) {
//     if (window.confirm(`Are you sure you want to remove the appointment?`)) {
//         // this.removeAppointment(appointment.id)
//     }
// }

// onMounted(getAllAppointments)
// onMounted(getAvailableServices);
onMounted(getAppointments)

</script>
