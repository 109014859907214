import script from "./BookAppointment.vue?vue&type=script&setup=true&lang=js"
export * from "./BookAppointment.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QStepper,QStep,QOptionGroup,QDate,QBtn,QPopupEdit,QInput,QCheckbox});
