<template>
    <q-header elevated class="bg-primary text-white">
        <q-toolbar>
            <q-btn dense flat round icon="menu" @click="toggleLeftDrawer"/>
            <router-link to="/">
                <div class="logo-container">
                    <q-img src="@/assets/imgs/logo-yy.png" style="height: 38px; max-width: 128px"/>
                </div>
            </router-link>

            <q-toolbar-title>Simple Harmony Appointment Booking</q-toolbar-title>

            <!--            <q-toolbar-title>-->
            <!--                <q-avatar>-->
            <!--                    <img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-white.svg">-->
            <!--                </q-avatar>-->
            <!--                Title-->
            <!--            </q-toolbar-title>-->

            <q-btn v-show="authStore.user" flat round dense icon="account_circle">
                <q-menu>
                    <q-list style="min-width: 100px">
                        <q-item clickable v-close-popup :to="'/user/settings'">
                            <q-item-section>Account Settings</q-item-section>
                        </q-item>
                        <q-separator/>
                        <q-item clickable v-close-popup @click="authStore.logout()">
                            <q-item-section>Logout</q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" side="left" overlay elevated>
        <q-list>

            <template v-for="(menuItem, index) in menuList" :key="index">
                <q-item clickable :active="menuItem.path === $route.path" :to="menuItem.path" v-ripple>
                    <q-item-section avatar>
                        <q-icon :name="menuItem.icon" />
                    </q-item-section>
                    <q-item-section>
                        {{ menuItem.label }}
                    </q-item-section>
                </q-item>
                <q-separator :key="'sep' + index"  v-if="menuItem.separator" />
            </template>

        </q-list>
    </q-drawer>

    <!--    <nav v-show="authStore.user" class="navbar navbar-expand navbar-dark bg-dark">-->
    <!--        <div class="navbar-nav">-->
    <!--            <router-link to="/" class="nav-item nav-link">Home</router-link>-->
    <!--            <router-link to="/users" class="nav-item nav-link">Users</router-link>-->
    <!--            <button @click="authStore.logout()" class="btn btn-link nav-item nav-link">Logout</button>-->
    <!--        </div>-->
    <!--    </nav>-->
</template>

<script setup>
import {ref} from 'vue'
import {useAuthStore} from '@/stores';

const authStore = useAuthStore();
const leftDrawerOpen = ref(false)
const menuList = [
    {
        icon: 'home',
        label: 'Dashboard',
        path: '/',
        separator: true
    },
    {
        icon: 'today',
        label: 'Book Appointment',
        path: '/appointments/schedule',
        separator: false
    },
    {
        icon: 'calendar_month',
        label: 'View Appointments',
        path: '/appointments',
        separator: false
    },
    {
        icon: 'description',
        label: 'Forms',
        path: '/forms',
        separator: true
    },
    {
        icon: 'account_box',
        label: 'Patient Info',
        path: '/patient/info-sheet',
        separator: true
    },
    {
        icon: 'account_settings',
        label: 'Account Settings',
        path: '/user/settings',
        separator: false
    }
]
function toggleLeftDrawer() {
    leftDrawerOpen.value = !leftDrawerOpen.value
}

</script>
