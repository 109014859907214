/**
 * Convert a boring date string in a human-readable formatted date string that has meaning
 * @param dateStr A string in the format 'YYYY-MM-DD'
 * @returns {string} A string in hte format 'DayOfWeek, Month 00, YYYY'
 */
export const convertToLongDate = (dateStr) => {
    const dateParts = dateStr.split("-")
    const year = parseInt(dateParts[0])
    const month = parseInt(dateParts[1]) - 1
    const day = parseInt(dateParts[2])

    const date = new Date(year, month, day)

    const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric"
    }

    const formattedDate = date.toLocaleDateString(undefined, options)

    return formattedDate
}

/**
 * Convert a 12-hr clock string into a 24-hr clock string.
 * @param timeStr A string in the format 'HH:MM (AM|PM)'
 * @param inclSec bool include seconds in the converted string
 * @returns {string} A string in the 24-hr format 'HH:MM'. if inclSec is set to true, then the returned string will have
 * the format 'HH:MM:SS'
 */
export const convertTo24HrClock = (timeStr, inclSec = false) => {
    let timeStrArray = timeStr.split(' ')
    let [hours, minutes] = timeStrArray[0].split(':').map(Number);
    if (hours === 12 && timeStrArray[1] === 'AM') {
        hours = 0
    } else if (hours < 12 && timeStrArray[1] === 'PM') {
        hours += 12
    }

    let newTimeStr = ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes)
    if (inclSec) {
        newTimeStr += ':00'
    }

    return newTimeStr
}

/**
 * Convert a 24-hr clock string into a 12-hr clock string
 * @param time24h A string in the 24-hr format 'HH:MM'
 * @returns {string} A string in the format 'HH:MM (AM|PM)'
 */
export const convertFrom24HrClock = (time24h) => {
    const [hours, minutes] = time24h.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    const time12h = new Intl.DateTimeFormat([], {
        hour: "numeric",
        minute: "numeric",
        hour12: true
    }).format(date);

    return time12h;
}

/**
 * Increase the given time string by a specific amount
 * @param timeStr The time string should be in the format 'HH:MM'
 * @param hrsToAdd
 * @param minsToAdd
 * @returns {`${*}:${*}`}
 */
export const addToTime = (timeStr, hrsToAdd = 1, minsToAdd = -1) => {
    const [hours, minutes] = timeStr.split(':').map(Number);

    return `${hours + hrsToAdd}:${minutes + minsToAdd}`
}
