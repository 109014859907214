import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import { router } from './router';
import './assets/styles/main.scss'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

// createApp(App).use(createPinia()).use(Quasar, quasarUserOptions).use(router).mount('#app');

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(Quasar, quasarUserOptions);

app.mount('#app');