<template>
    <div class="row">
        <div class="col">
            <div class="page-title q-pb-md q-pt-md">
                Forms and Documents
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <q-list bordered separator>
                <q-item clickable :to="'/patient/info-sheet'" v-ripple>
                    <q-item-section>Patient Information Sheet</q-item-section>
                </q-item>
                <q-item clickable :to="'/forms/health-history'" v-ripple>
                    <q-item-section>Personal Medical &amp; Family Health History</q-item-section>
                </q-item>
                <q-item clickable :to="'/forms/informed-consent'" v-ripple>
                    <q-item-section>Informed Consent for Acupuncture Treatment ans Care</q-item-section>
                </q-item>
                <q-item clickable :to="'/forms/hipaa-notice'" v-ripple>
                    <q-item-section>HIPAA Notice of Privacy Practices</q-item-section>
                </q-item>
                <q-item clickable :to="'/forms/arbitration-agreement'" v-ripple>
                    <q-item-section>Arbitration Agreement</q-item-section>
                </q-item>
            </q-list>
        </div>
    </div>
</template>

<script setup>
</script>
