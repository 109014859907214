<script>

// function currentYear() {
//     return new Date().getFullYear()
// }

</script>

<template>
    <q-footer class="bg-grey-8 text-white">
        <q-toolbar>
            <q-toolbar-title>
                <q-avatar>
                    <img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-white.svg">
                </q-avatar>
                <div>Simple Harmony</div>
            </q-toolbar-title>
        </q-toolbar>
    </q-footer>
<!--    <div class="about__bamboo"></div>-->
<!--    <footer class="footer">-->
<!--        <div class="footer__item footer__item&#45;&#45;left">-->
<!--            <p class="footer__paragraph small">Unlocking the power of holistic healing and ancient wisdom to restore-->
<!--                balance-->
<!--                and harmony in your mind, body, and spirit.</p>-->
<!--            <p class="footer__paragraph">Copyright &copy; {{ currentYear }} Simple Harmony &amp; Design By Gianfranco-->
<!--                Toti</p>-->
<!--        </div>-->
<!--    </footer>-->
</template>
