import {Layout, PatientInfo} from '@/views/patient'

export default {
    path: '/patient',
    component: Layout,
    children: [
        // {path: '', component: PatientInformation},
        // {path: 'information', component: PatientInformation},
        {path: 'info-sheet', component: PatientInfo},
    ]
}
