
import './styles/quasar.scss'
// import iconSet from 'quasar/icon-set/material-icons-round.js'
import iconSet from 'quasar/icon-set/material-icons.js'
// import iconSet from 'quasar/icon-set/fontawesome-v6.js'
// import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons/material-icons.css'
// import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
  },
  iconSet: iconSet
}
