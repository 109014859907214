<template>
    <q-input rounded outlined
             v-model="value"
             :type="isPwd ? 'password' : 'text'"
             :error-message="errorMessage"
             :error="!!errorMessage">
        <template v-slot:append>
            <q-icon
                    :name="isPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd = !isPwd"
            />
        </template>
    </q-input>
</template>

<script>
import {useField} from "vee-validate"
import {ref} from "vue"

const isPwd = ref(true)

export default {
    name: "QInputWithValidation",
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const {errorMessage, value} = useField(props.name);

        return {
            errorMessage,
            value,
            isPwd
        }
    },
}
</script>
