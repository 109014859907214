import {Layout, BookAppointment, ScheduledAppointments} from '@/views/appointments';

export default {
    path: '/appointments',
    component: Layout,
    children: [
        {path: '', component: ScheduledAppointments},
        {path: 'schedule', component: BookAppointment}
    ]
}
