<template>
    <div class="row">
        <div class="col">
            <div class="page-title q-pb-md q-pt-md">
                Book an appointment
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col q-ma-md">
            <div class="row q-mb-lg">
                <q-stepper v-model="state.currentStep" header-nav ref="stepper" color="primary" animated>
                    <q-step :name="1" title="Select service" active-icon="list" icon="list" :done="state.currentStep > 1" :header-nav="state.currentStep > 1">
                    </q-step>
                    <q-step :name="2" title="Choose a date" active-icon="calendar_month" icon="calendar_month" :done="state.currentStep > 2" :header-nav="state.currentStep > 2">
                    </q-step>
                    <q-step :name="3" title="Choose a time" active-icon="schedule" icon="schedule" :done="state.currentStep > 3" :header-nav="state.currentStep > 3">
                    </q-step>
                    <q-step :name="4" title="Confirm" active-icon="task_alt" icon="task_alt" :done="state.currentStep > 4" :header-nav="state.currentStep > 4">
                    </q-step>
                </q-stepper>
            </div>
            <div class="row q-mb-xl">
                <div class="col  q-pl-md q-pt-lg" :class="{ hidden: state.currentStep === 4 }">
                    <div class="row">
                        <div class="col">
                            <q-option-group
                                    @update:modelValue="handleServiceSelected"
                                    :options="state.services"
                                    type="radio"
                                    v-model="group"
                            />
                        </div>
                    </div>
                </div>
                <div class="col" :class="{ invisible: state.currentStep < 2 }">
                    <div class="row">
                        <div class="col" ref="dateContainer">
                            <template v-if="state.availableDatesFetched">
                                <q-date v-model="dateInput" minimal :options="availableDatesOptions" />
                            </template>
                        </div>
                    </div>
                </div>
                <!--//TODO add is state.dateSelected triggering visibility of this, below col-->
                <div class="col" :class="{ invisible: state.currentStep < 3 }">
                    <div class="row available-times">
                        <div class="col">
                            <q-btn v-for="(item, index) in availableTimes" :key="index" color="secondary" size="md"
                                   :class="{'notSelected': item.notSelected}"
                                   @click="handleTimeSelected(item.time)">
                                {{item.time}}
                            </q-btn>
                        </div>
                    </div>
                </div>
                <div class="col" :class="{ hidden: state.currentStep < 4 }">
                    <div class="row">
                        <div class="col col-confirm">
                            <div class="row">
                                <div class="col">You've selected:</div>
                            </div>
                            <div class="row q-mb-md selected-date-time">
                                <div class="col">{{selectedWeekday}}<br />{{selectedDateTime}}</div>
                            </div>
                            <div class="row q-mb-lg">
                                <div class="col">
                                    <q-btn rounded color="primary">
                                        {{ notesLabel }}
                                        <q-popup-edit v-model="apptNotes" auto-save v-slot="scope">
                                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                                        </q-popup-edit>
                                    </q-btn>
                                </div>
                            </div>
                            <div class="row q-mb-md">
                                <div class="col sms-checkbox sms-agree-text" v-bind:class="{'required-field': state.requiredField}">
                                    <q-checkbox v-model="receiveSms" color="secondary" label="I agree to receive SMS alerts to
                             my phone number notifying me of my upcoming appointment."/>
                                    <!--//TODO on set disable state.requiredField to false -->
                                </div>
                            </div>
                            <div class="row book-btn-container">
                                <div class="col self-end">
                                    <q-btn rounded color="accent" size="lg" @click="bookAppointmentClicked">Book Your Appointment</q-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
//TODO
//TODO need to add default provider (for now, selectable in the future)
//TODO need to add ability to enter some notes by the user
//TODO
import {onMounted, reactive, watch, ref} from "vue";
import { QDate } from 'quasar'
import {convertTo24HrClock} from '@/utils/date'
import {useServiceStore, useProviderStore} from '@/stores'
import {addAppointment} from '@/utils/api/appointments'

const LBL_NOTES = {
    empty: 'Add notes or comments',
    full: 'Update your comments'
}

const serviceStore = useServiceStore()
const providerStore = useProviderStore()
let notesLabel = ref(LBL_NOTES.empty)
const dateContainer = ref(null)
let availableTimes = []
const dateInput = ref('')
let selectedWeekday = ref('')
let selectedDateTime = ref('')
const state = reactive({
    requiredField: false,
    currentStep: 1,
    services: [],
    availableDatesFetched: false
});
const apptNotes = ref('')
const receiveSms = ref(false)
const group = ref(null)
let availableDateTimesCache = {
    dates: [],
    datetimes: []
}
let apptData = {
    'provider': 1
}

/**
 * Get the available services from the API endpoint
 * @returns {Promise<void>}
 */
async function getAvailableServices() {
    console.log('getting services...')
    state.services = []
    if (await serviceStore.fetchServicesByProvider(1)) {
        let services = serviceStore.servicesByProvider[1]
        // console.log('>> services: ', services)
        for (let i = 0; i < services.length; i++) {
            // console.log('service name: ', services[i].name)
            state.services.push(
                {
                    label: services[i].name,
                    value: services[i].id
                }
            )
        }
        // console.log('>> state.services: ', state.services)
    } else {
        console.log('fetchServicesByProvider errored')
    }
}

/**
 * Get the available dates from the API endpoint
 * @returns {Promise<void>}
 */
async function getAvailableDates() {
    console.log('fetching available dates....')
    if (await providerStore.fetchAvailableDateTimes(1)) {
        const data = providerStore.providerDateTimes[1]
        availableDateTimesCache.dates = []
        availableDateTimesCache.datetimes = []
        for (let i = 0; i < data.length; i++) {
            availableDateTimesCache.dates.push(data[i].date.replaceAll('-', '/'))
            availableDateTimesCache.datetimes[data[i].date.replaceAll('-', '')] = {
                start: data[i].startTime.replace(':00', ''),
                end: data[i].endTime.replace(':00', '')
            }
        }
        console.log('BUILT availableDateTimesCache: ', availableDateTimesCache)
        state.availableDatesFetched = true
    } else {
        console.log('could not get available date times for provider')
    }
}

/**
 * Get the available times for the given date
 * @param date
 */
function getAvailableTimes(date) {
    console.log('update times for: ', date.valueOf())
    let timeRange = availableDateTimesCache.datetimes[date.valueOf().replaceAll('/', '')]
    availableTimes = []; // reset
    const interval = 30; // minutes
    const startTime = new Date(`1970-01-01T${timeRange.start}:00`);
    const endTime = new Date(`1970-01-01T${timeRange.end}:00`);

    let currentTime = startTime;
    while (currentTime < endTime) {
        availableTimes.push({
            'time': currentTime.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'}),
            'notSelected': false
        });
        currentTime.setMinutes(currentTime.getMinutes() + interval);

        if (currentTime >= new Date(endTime.getTime() - 60*60*1000)) {
            availableTimes.push({
                'time': new Date(endTime.getTime() - 60*60*1000).toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'}),
                'notSelected': false
            });
            break;
        }

        if (currentTime > endTime) {
            availableTimes.push({
                'time': endTime.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'}),
                'notSelected': false
            });
        }
    }
}

function availableDatesOptions(providedDate) {
    return availableDateTimesCache.dates.includes(providedDate)
}

function updateSelectedTimes(currTime) {
    state.requiredField = false;
    for (let i = 0; i < availableTimes.length; i++) {
        let curr = availableTimes[i];
        if (currTime === null) {
            curr.notSelected = false;
        } else {
            curr.notSelected = (curr.time !== currTime);
        }
    }
}

function handleServiceSelected(value) {
    console.log('value changed: ', value)
    apptData['service'] = value
    state.currentStep = 2
}

function handleTimeSelected(selectedTime) {
    state.requiredField = false;

    let time = convertTo24HrClock(selectedTime, true)
    const timeObj = new Date(dateInput.value.replaceAll('/', '-') + 'T' + time);
    console.log('date init string: ', dateInput.value.replaceAll('/', '-') + 'T' + time)
    console.log('timeObj: ', timeObj)
    apptData['startTime'] = convertTo24HrClock(selectedTime)

    console.log('time selected: ', selectedTime)

    let options = {weekday: 'long'};
    selectedWeekday.value = timeObj.toLocaleDateString('en-US', options)
    options = {year: 'numeric', month: 'long', day: 'numeric'};
    selectedDateTime.value = timeObj.toLocaleDateString('en-US', options) + ' ' + selectedTime
    updateSelectedTimes(selectedTime)

    const oneHourLater = new Date(timeObj);
    oneHourLater.setHours(timeObj.getHours() + 1);
    apptData['endTime'] = oneHourLater.toLocaleTimeString([],
        { hour: '2-digit', minute: '2-digit', hour12: false, hourCycle: 'h23' });

    state.currentStep = 4
}

/**
 * Watch for the date calendar to change value to go to the next step
 * TODO BUG: if you go back to this step, you can't go forward without changing the date
 */
watch(dateInput, (newValue, oldValue) => {
    console.log(`Model updated from ${oldValue} to ${newValue}`);
    updateSelectedTimes(null)
    getAvailableTimes(newValue)
    apptData['date'] = newValue
    state.currentStep = 3
});

/**
 * Watch the SMS checkbox so that if required state is triggered, we can disable it once it becomes checked
 * TODO BUG: if you go back steps, then the checkbox is still checked, should get unchecked
 */
watch(receiveSms, (newValue, oldValue) => {
    if (!oldValue && state.requiredField) {
        state.requiredField = false
    }
})

watch(apptNotes, (newValue, oldValue) => {
    if (oldValue === '') {
        notesLabel = LBL_NOTES.full
    }
    if (newValue === '') {
        notesLabel = LBL_NOTES.empty
    }
    apptData['notes'] = newValue
})

function bookAppointmentClicked() {
    console.log('NOTES: ', apptNotes.value)
    if (!receiveSms.value) {
        console.log('checkbox is NOT checked')
        state.requiredField = true;
        return false
    }
    console.log('Appointment booked! (Appointment=', apptData, ')')
    let response = addAppointment(apptData)
    console.log('response: ', response)
}

onMounted(getAvailableServices);
onMounted(getAvailableDates);

</script>
