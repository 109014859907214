import axios from 'axios'

const HOST = 'https://book.simpleharmony.co'

// Function to make a GET request to fetch appointments
// export const getAppointments = async () => {
//     try {
//         const response = await axios.get('/api/appointments')
//         const appointments = response.data
//         return appointments
//     } catch (error) {
//         console.log(error)
//     }
// }

// Function to make a POST request to add an appointment
export const addAppointment = async (appointment) => {
    try {
        const response = await axios.post(HOST + '/api/appointments', appointment)
        const newAppointment = response.data
        return newAppointment
    } catch (error) {
        console.log(error)
    }
}

// Function to make a PUT request to update an appointment
export const updateAppointment = async (appointment) => {
    try {
        const response = await axios.put(`/api/appointments/${appointment.id}`, appointment)
        const updatedAppointment = response.data
        return updatedAppointment
    } catch (error) {
        console.log(error)
    }
}

// Function to make a DELETE request to remove an appointment
export const removeAppointment = async (appointmentId) => {
    try {
        const response = await axios.delete(`/api/appointments/${appointmentId}`)
        const removedAppointment = response.data
        return removedAppointment
    } catch (error) {
        console.log(error)
    }
}
