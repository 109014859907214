<template>
    <q-card class="home-tile-card" @click="$router.push(tileContent.path)">
        <q-img :src="tileContent.image" style="min-width: 300px">
            <div class="text-h5 absolute-bottom text-center">
                {{ tileContent.label }}
            </div>
        </q-img>
    </q-card>

</template>

<script>

export default {
    name: 'HomeTile',
    props: {
        tileContent: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isHovering: false
        }
    }
}
</script>