import {Layout, ArbitrationAgreement, FormsScreen, HealthHistory, HipaaNotice, InformedConsent} from '@/views/forms';

export default {
    path: '/forms',
    component: Layout,
    children: [
        {path: '', component: FormsScreen},
        {path: 'arbitration-agreement', component: ArbitrationAgreement},
        {path: 'health-history', component: HealthHistory},
        {path: 'hipaa-notice', component: HipaaNotice},
        {path: 'informed-consent', component: InformedConsent}
    ]
}
