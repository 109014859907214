<template>
    <div v-if="user">
        <h4>Welcome, {{ user.firstName }}</h4>
        <!--TODO notify if there's an upcoming appt -->
        <div class="full-width row wrap justify-center items-start content-start">
            <div class="q-pa-md row items-start q-gutter-md">
                <template v-for="(homeTileItem, index) in homeTileList" :key="index">
                    <HomeTile :tile-content="homeTileItem"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import {storeToRefs} from 'pinia';
import {useAuthStore} from '@/stores';
import {HomeTile} from "@/components";

const authStore = useAuthStore();
const {user} = storeToRefs(authStore);

const homeTileList = [
    {
        image: '/imgs/tiles/book-appointment.jpg',
        icon: 'cloud_upload',
        label: 'Book Appointment',
        path: '/appointments/schedule',
        separator: false
    },
    {
        image: '/imgs/tiles/calendar.jpg',
        icon: 'table_view',
        label: 'View Upcoming Appointments',
        path: '/appointments',
        separator: false
    },
    {
        image: '/imgs/tiles/forms.jpg',
        icon: 'settings',
        label: 'Intake Forms',
        path: '/forms',
        separator: false
    },
]
</script>
