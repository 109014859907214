import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers';
import { router } from '@/router';
import { useAlertStore } from '@/stores';

// const baseUrl = `${import.meta.env.VITE_API_URL}/users`;
const baseUrl = `https://book.simpleharmony.co/api`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        isAuthenticated: false, // currently using user for authenticated state, but could we use this?
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null
    }),
    actions: {
        async login(email, password) {
            try {
                //TODO change 'user' to 'resp' & process success, message, & data (update login BE to include user data)
                // const user = await fetchWrapper.post(`${baseUrl}/login`, { email, password });
                const resp = await fetchWrapper.post(`${baseUrl}/login`, { email, password });

                console.log('resp:', resp)
                if (resp.success) {
                    // update pinia state
                    this.user = resp.data;

                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(resp.data));
                    this.isAuthenticated = true

                    // redirect to previous url or default to home page
                    router.push(this.returnUrl || '/');
                } else {
                    //TODO error message
                    console.log('not a success...')
                }
            } catch (error) {
                const alertStore = useAlertStore();
                alertStore.error(error);
            }
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            this.isAuthenticated = false
            router.push('/account/login');
        },
        async resetPassword(email) {
            const resp = await fetchWrapper.post(`${baseUrl}/pwd-forgot`, { email });
            console.log('resp: ', resp)
        }
    }
});
