import { defineStore } from 'pinia';
import axios from "axios";

const HOST = 'https://book.simpleharmony.co'

export const useProviderStore = defineStore({
    id: 'provider',
    state: () => ({
        providers: [],
        providerDateTimes: []
    }),
    actions: {
        fetchProviders() {

        },
        async fetchAvailableDateTimes(providerId) {
            try {
                const res = await axios.get(HOST + `/api/provider/${providerId}/datetimes`);
                let respObj = res.data
                if (respObj.statusCode === 200 && respObj.success) {
                    console.log('::STORE date times: ', respObj.data)
                    this.providerDateTimes[providerId] = respObj.data

                    return true
                }
            } catch (err) {
                console.log(err);
            }

            return false
        },
        addProvider(provider) {
            console.log('provider: ', provider)
        },
        updateProvider(provider) {
            console.log('provider: ', provider)
        },
        disableProvider(providerId) {
            console.log('providerId: ', providerId)
        },
        addServiceToProvider(providerId, service) {
            console.log('providerId: ', providerId, ', service:', service)
        },
        removeServiceFromProvider(providerId, serviceId) {
            console.log('providerId: ', providerId, ', serviceId:', serviceId)
        },
        addProviderAvailableDates(providerId, dateTimesArray) {
            console.log('providerId: ', providerId, ', dateTimesArray:', dateTimesArray)
        },
        removeProviderAvailableDates(providerId, dateTimesArray) {
            console.log('providerId: ', providerId, ', dateTimesArray:', dateTimesArray)
        }
    }
});
