<template>
    <div class="row">
        <div class="col">
            <div class="page-title q-pb-md q-pt-md">
                Sign up to schedule your appointment
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ isSubmitting }" class="q-mb-md bamboo-right">
                <q-card bordered class="form-card">
                    <q-card-section>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="firstName"
                                        label="First Name"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="lastName"
                                        label="Last Name"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QInputWithValidation
                                        name="email"
                                        label="Email"
                                        placeholder="user@example.com"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QPasswordWithValidation
                                        name="password"
                                        label="Password"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col q-mb-sm">
                                <QPasswordWithValidation
                                        name="confirmPassword"
                                        label="Retype Password"
                                        placeholder=""
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col login-btn-container deco-link">
                                <q-btn rounded color="accent" size="md" padding="9px 24px" type="submit" :disabled="isSubmitting">
                                    <span v-show="isSubmitting" class="spinner-border spinner-border-sm q-mr-sm"></span>
                                    Sign Up
                                </q-btn>
                                <router-link to="login" class="btn btn-link">Cancel</router-link>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </Form>
        </div>
    </div>
</template>

<script setup>
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import { useUsersStore, useAlertStore } from '@/stores';
import { router } from '@/router';
import {QInputWithValidation, QPasswordWithValidation} from '@/components/form'

const schema = Yup.object().shape({
    firstName: Yup.string()
        .required('First Name is required'),
    lastName: Yup.string()
        .required('Last Name is required'),
    email: Yup.string()
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
});

async function onSubmit(values) {
    const usersStore = useUsersStore();
    const alertStore = useAlertStore();
    try {
        await usersStore.register(values);
        await router.push('/account/login');
        alertStore.success('Registration successful');
    } catch (error) {
        alertStore.error(error);
    }
}
</script>
